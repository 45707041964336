import { useCreateContext } from "../create-context";
import { reducer, initialState } from "./app.reducer";

export const useCreateContextHook = () => {
  const [state, useDispatch, provider] = useCreateContext(
    initialState,
    reducer
  );
  const useStickyState = state;
  const useStickyDispatch = useDispatch;
  const StickyProvider = provider;

  return {
    useStickyState,
    useStickyDispatch,
    StickyProvider,
  };
};
