import React from 'react';
import 'swiper/css';
import "swiper/css/pagination"
import "swiper/css/navigation"
import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';

import { useCreateContextHook } from 'contexts/app/app.provider';
import Seo from 'components/seo';
import Layout from 'components/layout';

import Banner from 'sections/banner';
import Services from 'sections/services';
import Testimonials from 'sections/testimonials';
import CustomerSupport from 'sections/customer-support';
import Feature from 'sections/feature';
import CallToAction from 'sections/call-to-action';
import BoostAgencies from 'sections/boost-agencies';

export default function IndexPage() {
  const { StickyProvider } = useCreateContextHook();

  return (
    <StickyProvider>
      <Layout>
        <Seo title="Goat - Financial Services" />
        <Banner />
        <Services />
        <BoostAgencies />
        <Testimonials />
        <CustomerSupport />
        <Feature />
        <CallToAction />
      </Layout>
    </StickyProvider>
  );
}
