/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';

export default function Logo() {
  return (
    <Link
      path="/"
      sx={{
        variant: 'links.logo',
      }}
    >
      <Image src='https://user-images.githubusercontent.com/48744933/135133971-631dd38e-c94f-4389-b8d7-ff480318947e.png' alt="startup landing logo" sx={styles.companyLogo}/>
    </Link>
  );
}

const styles = {
  companyLogo: {
    filter: "grayscale(1)",
    opacity: 0.6
  }
}