import React from "react";
import { Image, Box, Container, Grid, Heading, Text } from "theme-ui";
import BlockTitle from "components/block-title";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

SwiperCore.use([Autoplay,Pagination,Navigation]);

const SERVICES_DATA = [
  {
    icon: "https://user-images.githubusercontent.com/48744933/135282172-6abfe127-ca65-44fe-8b50-f24464443787.png",
    title: "S.I.I. integration",
    text: "No need to manually input information. Your company's data is already available for you.",
  },
  {
    icon: "https://user-images.githubusercontent.com/48744933/135281931-b6cc9bc2-7e1d-4d3c-b9e0-b505844e7010.png",
    title: "Automatic reports",
    text: "Get financial, operational and benchmark indicators on just a few clicks.",
  },
  {
    icon: "https://user-images.githubusercontent.com/48744933/135281759-2e7b3f0c-681a-48f8-8917-d6e1d7c2003f.png",
    title: "Bank Integration (soon)",
    text: "Consolidate your bank information in one place. No more tedious manual processes.",
  },
];

const Services = () => {
  return (
    <Box sx={styles.services} id="services">
      <Container>
        <BlockTitle
          slogan="Our Services"
          title="Approachable company finances"
          styles={styles.blockTitle}
        />
        <Grid sx={styles.grid}>
          <Swiper
            slidesPerView={2}
            centeredSlides={true}
            spaceBetween={50}
            pagination={{ clickable: true }}
            sx={styles.swiper}
            loop={true}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false
            }}
          >
            {SERVICES_DATA.map((service, index) => (
              <SwiperSlide key={`service-${index}`}>
                <Box
                  className="service-card"
                  sx={styles.serviceCard}
                  key={`service-post-${index}`}
                >
                  <Box sx={styles.icon}>
                    <Image src={service.icon} alt="" sx={styles.icon_image} />
                  </Box>
                  <Heading as="h3">{service.title}</Heading>
                  <Text as="p">{service.text}</Text>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  swiper: {
    width: "100%",
    height: "100%",
  },
  services: {
    pt: ["80px", null, null, null, null, null, "140px"],
    overflowX: "auto",
  },
  blockTitle: {
    textAlign: "center",
  },
  grid: {
    display: "flex",
    gridGap: ["30px", null, null, null, null, "60px"],
    gridTemplateColumns: [
      "1fr",
      null,
      null,
      "1fr 1fr",
      null,
      "1fr 1fr 1fr 1fr",
    ],
  },
  icon: {
    display: "flex",
    ml: "auto",
    mr: "auto",
    width: ["250px", null, null, "200px"],
    height: ["300px", null, null, "250px"],
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ["20px", null, null, "40px"],
    backgroundImage: "#FFFFFF",
  },
  icon_image: {

    minWidth: 0,
    maxWidth: "inherit",
  },
  serviceCard: {
    textAlign: "center",
    h3: {
      margin: 0,
      fontSize: ["18px", null, null, 3],
      fontWeight: "bold",
      lineHeight: 1,
      color: "black",
      mt: ["30px", null, null],
      mb: ["20px", null, null],
    },
    p: {
      margin: 0,
      fontSize: [0, null, null, "15px"],
      color: "heading_secondary",
      width: "100%",
      maxWidth: [null, null, null, null, "84%", "100%"],
      mx: [null, null, null, null, "auto", "0"],
    },
  },
};
